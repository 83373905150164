import {createBrowserRouter} from "react-router-dom";
import {lazy, Suspense} from "react";
// import Error404 from "../components/error/error404";

const Loading = <div>Loading...</div>
const MainPage = lazy(() => import("../pages/index"))
const EtestPage = lazy(() => import("../pages/etest"))
const IsoPage = lazy(() => import("../pages/iso"))
const MediaPage = lazy(() => import("../pages/media"))

const root = createBrowserRouter([

	{
		path : "/",
		element : <Suspense fallback={Loading}><MainPage/></Suspense>,
		// errorElement : <Error404/>
	},

	{
		path : "/etest",
		element : <Suspense fallback={Loading}><EtestPage/></Suspense>,
	},

	{
		path : "/iso",
		element : <Suspense fallback={Loading}><IsoPage/></Suspense>,
	},

	{
		path : "/media",
		element : <Suspense fallback={Loading}><MediaPage/></Suspense>,
	},





])

export default root