import useMobileDetect from './hooks/commons/mobileDetect'
import {RouterProvider} from "react-router-dom";
import root from "./router/root";
import mobileRoot from "./router/mobileRoot";
import {useRecoilState} from "recoil";
import {recoilLangState} from "./state/recoilLangState";

function App() {
  const { checkProcessing, isMobile } = useMobileDetect()

  const queryString = window.location.search; // "?key=value&key2=value2"
  const params = new URLSearchParams(queryString);

  const [lang, setLang] = useRecoilState(recoilLangState)

  if(params.get("lang") === 'id'){
    setLang('id')
  }else if(params.get("lang") === 'en'){
    setLang('en')
  }

  if (checkProcessing) return <></>
  if (isMobile) return <RouterProvider router={mobileRoot}/>
  return <RouterProvider router={root}/>
}

export default App
