import {createBrowserRouter} from "react-router-dom";
import {lazy, Suspense} from "react";
// import Error404 from "../components/error/error404";

const Loading = <div>Loading...</div>
const MobileMainPage = lazy(() => import("../pages/mobile/index"))
const MobileEtestPage = lazy(() => import("../pages/mobile/etest"))
const MobileIsoPage = lazy(() => import("../pages/mobile/iso"))
const MobileMediaPage = lazy(() => import("../pages//mobile/media"))

const root = createBrowserRouter([

	{
		path : "/",
		element : <Suspense fallback={Loading}><MobileMainPage/></Suspense>,
		// errorElement : <Error404/>
	},

	{
		path : "/etest",
		element : <Suspense fallback={Loading}><MobileEtestPage/></Suspense>,
	},

	{
		path : "/iso",
		element : <Suspense fallback={Loading}><MobileIsoPage/></Suspense>,
	},

	{
		path : "/media",
		element : <Suspense fallback={Loading}><MobileMediaPage/></Suspense>,
	},





])

export default root